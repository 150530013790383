import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import AllTravels from "./Pages/AllTravels";
import Travel from "./Pages/Travels/[id]";
import Admin from "./Pages/Admin.js";
import LoggedIn from "./Pages/LoggedIn.js";
import { TravelsProvider } from "./Context/TravelsContext";
import { UserAuthContextProvider } from "./Context/UserAuthContext.js";
import Signup from "./Components/Signup.js";
import Login from "./Components/Login.js";

import "./App.css";

function App() {
  return (
    <div className="App">
      <UserAuthContextProvider>
        <TravelsProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Admin" element={<Admin />} />
            <Route path="/AllTravels" element={<AllTravels />} />
            <Route path="/Travels/:id" element={<Travel />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/LoggedIn" element={<LoggedIn />} />
          </Routes>
        </TravelsProvider>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
