import { React, useContext } from "react";
import { Link } from "react-router-dom";

import WorldMap from "../Components/Map.js";
import Banner from "../Components/Banner.js";
import AllTravels from "./AllTravels.js";
import TravelsContext from "../Context/TravelsContext.js";

function Home() {
  const { travels, visitedCitiesNumber, visitedCountriesNumber } =
    useContext(TravelsContext);

  return (
    <div className="Home">
      <Link to={"/Alltravels"} element={<AllTravels travels={travels} />}>
        <h1>All Travels</h1>
      </Link>
      <a href="/Admin">Admin</a> <br />
      <div className="top-banner">
        <Banner />
      </div>
      <div className="Map">
        <WorldMap />
      </div>
      <div className="bottom-banner">
        <span>{visitedCountriesNumber}</span> Countries /{" "}
        <span>{visitedCitiesNumber}</span> Cities
      </div>
    </div>
  );
}

export default Home;
