// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCAnkyqXMEUIROLowjvUFqNXxN96cRejEU",
  authDomain: "mouradknowsbest-photography.firebaseapp.com",
  projectId: "mouradknowsbest-photography",
  storageBucket: "mouradknowsbest-photography.appspot.com",
  messagingSenderId: "873363380813",
  appId: "1:873363380813:web:22bbce42d68faef358da04",
  measurementId: "G-P753PGYFGC"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const firestore = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const auth = getAuth();
export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};
export const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};