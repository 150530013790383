import React from "react";
import { useUserAuth } from "../Context/UserAuthContext";
import { useNavigate } from "react-router";
import AddANewTravel from "../Components/AddANewTravel";

function LoggedIn() {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/admin");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="loggedIn">
      <a href="/">Back Home</a> <br />
      <>
        <div className="user-profile">
          Welcome {user?.displayName} ,<br />
          {user && user?.email && (
            <div>
              <img src={user?.photoURL} alt=""></img>
              <span>{user?.email}</span>
            </div>
          )}
          <button variant="primary" onClick={handleLogout}>
            Log out
          </button>{" "}
        </div>

        <AddANewTravel />
      </>
    </div>
  );
}

export default LoggedIn;
