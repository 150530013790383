import { React, useRef, useEffect, useState, useContext } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { useNavigate } from "react-router-dom";
import TravelsContext from "../Context/TravelsContext.js";
import mapboxgl from "mapbox-gl";
import axios from "axios";

function WorldMap() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);

  const [lng, setLng] = useState(2.333333);
  const [lat, setLat] = useState(48.866667);
  const [zoom, setZoom] = useState(2.5);
  const navigate = useNavigate();

  const { travels } = useContext(TravelsContext);

  const getGeocodeFromName = async (CityName) => {
    const response = await axios.get(
      `${process.env.REACT_APP_MAPTILER_API}${CityName}.json?autocomplete=false&fuzzyMatch=true&limit=1&key=${process.env.REACT_APP_ACCESS_TOKEN}`
    );

    return [
      response?.data?.features[0]?.geometry?.coordinates[0],
      response?.data?.features[0]?.geometry?.coordinates[1],
    ];
  };

  const addMarker = (map, coordinates, CityName) => {
    marker.current = new mapboxgl.Marker({
      color: "yellowgreen", //"#2C3A7E", 
      scale: "0.5",
    }).setLngLat(coordinates);
    setZoom(2.25);
    setLng(coordinates[0]);
    setLat(coordinates[1]);

    marker.current
      .addTo(map.current)
      .getElement()
      .addEventListener("click", function (event) {
        event.stopPropagation();
        navigate(`/Travels/${CityName}`);
      });
  };

  const placeTravelOnMap = async (CityName) => {
    try {
      const coordinates = await getGeocodeFromName(CityName);
      addMarker(map, coordinates, CityName);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `${process.env.REACT_APP_MAP_STYLE_URL}`,
      center: [lng, lat],
      zoom: zoom,
    });

    travels.map(
      async (travel) => {
        await placeTravelOnMap(travel.city);
      },
      [lat, lng, zoom]
    );
  });

  return (
    <div>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
}
export default WorldMap;
