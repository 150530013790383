import React, { useState } from "react";
import { collection, addDoc } from "@firebase/firestore";
import { db, storage } from "../firebase-config.js";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

function AddANewTravel() {
  let [addedTravel, setAddedTravel] = useState({
    city: "",
    country: "",
    date: "",
    imgUrl: "",
  });
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const [progress, setProgress] = useState(0);
  const dbRef = collection(db, "travels");

  const handleChange = (evt) => {
    const value = evt.target.value;
    setAddedTravel({
      ...addedTravel,
      [evt.target.name]: value,
    });
  };

  function handleMultiPhotosUpload(event) {
    const selectedFiles = event.target.files;
    const filePromises = []; // Array to store promises for file processing
  
    // Validate file selection
    if (!selectedFiles || selectedFiles.length === 0) {
      console.error("Please select one or more image files.");
      return;
    }
  
    // Iterate through selected files
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
  
      // Validate file type and size
      if (!file.type.startsWith("image/")) {
        console.error(`${file.name} is not an image file.`);
        continue; // Skip to next file
      }
  
      if (file.size > 2097152) { // 2MB limit
        console.error(`${file.name} is too large (max 2MB).`);
        continue;
      }
  
      // Create a promise for each file
      filePromises.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            // Return the file as a Blob
            resolve(new Blob([reader.result], { type: file.type }));
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file); // Or reader.readAsDataURL(file) if needed
        })
      );
    }

  
    // Process resolved file data using Promise.all
    Promise.all(filePromises)
      .then((processedFiles) => {
        console.log("Processed files (Blobs):", processedFiles);
        setFiles(processedFiles); // Update files state with processed Blobs
      })
      .catch((error) => {
        console.error("Error processing files:", error);
        // Handle errors gracefully
      });
  }
  const uploadPhotosToFirebase = (files) => {

    // setFiles([]);

    const promises = [];
    files.map((file) => {

      const id = uuidv4();

      const storageRef = ref(storage, `/travels/${addedTravel.city}/${id}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
        promises.push(uploadTask);
        
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (error) => console.log(error),
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
            setUrls((prevState) => [...prevState, downloadURLs]);
            console.log("File available at", downloadURLs);
          });
          // setFiles([]);
          // setUrls([]);

        }


      );

      return "";
    });
    Promise.all(promises)
      .then(() => console.log("All images uploaded"))
      .then((err) => console.log(err));
  };

  const sendToFireBaseDB = async () => {
    if (!addedTravel.city || !addedTravel.country || !addedTravel.date) {
      alert("Please add Travel infos first!");
      return;
    }
    if (!files) {
      alert("Please upload photos first!");
    }
      
    uploadPhotosToFirebase(files);

    try {
      addDoc(dbRef, {
        city: addedTravel.city,
        country: addedTravel.country,
        date: addedTravel.date,
        imgUrl: urls,
        timestamp: new Date(),
      });

      // progressBar(storage, files);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {" "}
      <div className="add-a-new-travel">
        <form onChange={handleChange}>
          <input
            className="city"
            type="text"
            placeholder="CITY"
            name="city"
            value={addedTravel.city}
            onChange={handleChange}
          />{" "}
          <input
            className="country"
            type="country"
            placeholder="COUNTRY"
            name="country"
            value={addedTravel.country}
            onChange={handleChange}
          />
          <input
            className="date"
            type="date"
            name="date"
            value={addedTravel.date}
            onChange={handleChange}
          />
          <div className="upload-photos">
            <label>
              <div>
                <input
                  type="file"
                  onChange={(event) => handleMultiPhotosUpload(event)}
                  accept="/image/*"
                  multiple
                />
                <p>{progress} "% done"</p>
              </div>
            </label>{" "}
          </div>
        </form>
      </div>
      <div className="submit">
        <button onClick={() => sendToFireBaseDB()}>
          <h1>Add a travel</h1>
        </button>
      </div>{" "}
    </div>
  );
}

export default AddANewTravel;
