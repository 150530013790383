import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TravelsContext from "../../Context/TravelsContext.js";
import BouncingDotsLoader from "../../Components/BouncingDotsLoader.js";
import PhotosSlider from "../../Components/PhotosSlider";

function Travel() {
  const [cityObject, setCityObject] = useState([]);
  const { id } = useParams();

  const { travels } = useContext(TravelsContext);

  const findCityObject = async (cityObjects, cityName) => {
    const CityObject = cityObjects.find(
      (object) => object.city.toLowerCase() === cityName.toLowerCase()
    );

    if (!CityObject) {
      console.error("City not found:", cityName);
      return null;
    }

    setCityObject(CityObject);
  };

  const yo = async () => {
    return await findCityObject(travels, id);
  };

  useEffect(() => {
    yo();
  }, []);

  return (
    <div className="travel">
      <a href="/">Back Home</a>
      <h1> {cityObject?.city}</h1>

      <div>
        {cityObject ? (
          <>
            <PhotosSlider SliderData={cityObject?.imgUrl} />
            <h1>{cityObject?.date}</h1> 
            
            <h2>Ajouté le {new Date(cityObject?.timestamp?.seconds * 1000).toLocaleString()}</h2> 
          </>
        ) : (
          <div>
            Travels List Charging .. Please Wait
            <BouncingDotsLoader />
          </div>
        )}
      </div>
    </div>
  );
}

export default Travel;
