import React from "react";

function TravelCard({ city, country, date, imgUrl }) {
  return (
    <>
      <div className="travelCard">
        {" "}
        <img src={imgUrl} alt=""></img>
        <h1>{country}</h1>
        <h2>
          {city} -{" "}
          {new Date(date).toLocaleString("fr-FR", {
            year: "numeric",
            month: "numeric",
          })}
        </h2>
      </div>
    </>
  );
}

export default TravelCard;
