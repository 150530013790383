import React from "react";
import Login from "../Components/Login";

function Admin() {
  return (
    <div className="admin">
      <>
        <div className="p-4 box mt-3 text-center">
          <Login />
        </div>
        <div className="d-grid gap-2"></div>
      </>
    </div>
  );
}

export default Admin;
