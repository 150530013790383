import React, { useContext } from "react";
import TravelCard from "../Components/TravelCard.js";
import TravelsContext from "../Context/TravelsContext.js";
import BouncingDotsLoader from "../Components/BouncingDotsLoader.js";
import { useNavigate } from "react-router-dom";

function AllTravels() {
  const { travels } = useContext(TravelsContext);

  const navigate = useNavigate();

  return (
    <div className="travels-page">
      {" "}
      <a href="/">Back Home</a>
      <h1>All Travels</h1>
      <div className="travels-container">
        
        {travels ? (
          <>
            {travels?.map((travel) => (
              <ul>
                <li
                  key={travel?.id}
                  onClick={() => {
                    navigate(`/Travels/${travel?.city}`);
                  }}
                >
                   <TravelCard
                    city={travel?.city}
                    country={travel?.country}
                    date={travel?.date}
                    imgUrl={travel?.imgUrl}
                  />                   

                </li>
              </ul>
            ))}
          </>
        ) : (
          <div>
            Travels List Charging .. Please Wait
            <BouncingDotsLoader />
          </div>
        )}
      </div>
    </div>
  );
}

export default AllTravels;
