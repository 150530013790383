// Create the context
import React, { createContext, useState, useEffect } from "react";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../firebase-config.js";

const TravelsContext = createContext();

// Provider component to wrap your app
const TravelsProvider = ({ children }) => {
  const [visitedCountriesNumber, setVisitedCountriesNumber] = useState(0);
  const [visitedCitiesNumber, setVisitedCitiesNumber] = useState(0);

  const [travels, setTravels] = useState([]);

  const ref = collection(db, "travels");

  const fetchTravels = async () => {
    const querySnapshot = await getDocs(ref);

    const travelData = [];

    querySnapshot.forEach((travel) => {
      travelData.push({ id: travel?.id, ...travel?.data() });
    });

    const countries = new Set();

    for (const entry of travelData) {
      countries.add(entry.country);
    }

    setTravels(travelData);
    setVisitedCitiesNumber(travelData?.length);
    setVisitedCountriesNumber(countries?.size);

  };

  useEffect(() => {
    fetchTravels();
  }, []);

  return (
    <TravelsContext.Provider
      value={{
        travels,
        setTravels,
        visitedCitiesNumber,
        setVisitedCitiesNumber,
        visitedCountriesNumber,
        setVisitedCountriesNumber,
      }}
    >
      {children}
    </TravelsContext.Provider>
  );
};

export default TravelsContext;

export { TravelsContext, TravelsProvider };
